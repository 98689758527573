import React from "react";
import "firebase/analytics";
import "firebase/auth";
import FirebaseAuthContextProvider from "./src/context/FirebaseAuthContext";
import BlockUIProvider from "./src/context/BlockUIContext";
import "tailwindcss/dist/base.min.css";
import "./src/styles/Main.css";

export const wrapRootElement = ({ element }) => (
    <BlockUIProvider>
        <FirebaseAuthContextProvider>            
            {element}
        </FirebaseAuthContextProvider>
    </BlockUIProvider>
);
