import React, { useState, createContext } from "react";
import tw, { styled } from "twin.macro";
import { Transition } from "@headlessui/react";
import { css, cx } from "@emotion/css";

const BlockUI = styled.div`
    ${tw`fixed h-screen z-40 w-full bg-ternary bg-opacity-50 flex`}
`;

const Span = styled.span`
    ${tw`font-inter font-semibold text-white ml-5`}
`;

// import "styles/BlockUI.css";

const BlockUIContextProvider = ({ children }) => {
    const [blocking, setBlocking] = useState(false);
    const [spinner, setSpinner] = useState(1);
    const [message, setMessage] = useState(null);

    const dropdownMenuTwClassNames = {
        enter: cx(css(tw`transition-opacity ease-linear duration-300`)),
        enterFrom: cx(css(tw`opacity-0`)),
        enterTo: cx(css(tw`opacity-100`)),
        leave: cx(css(tw`transition-opacity ease-linear duration-300`)),
        leaveFrom: cx(css(tw`opacity-100`)),
        leaveTo: cx(css(tw`opacity-0`)),
    };

    return (
        <>
            <BlockUIContext.Provider
                value={{
                    setBlocking,
                    setSpinner,
                    setMessage,
                    blocking,
                    message,
                    spinner,
                }}
            >
                <>
                    <Transition show={blocking} {...dropdownMenuTwClassNames}>
                        {(ref) => (
                            <BlockUI>
                                <div
                                    //ref={ref}
                                    css={[
                                        tw`mx-auto my-auto text-gray-600 font-montserrat uppercase font-semibold`,
                                    ]}
                                >
                                    <img
                                        css={[tw`inline-block w-20 h-20`]}
                                        src={require(`../images/animations/spinner${spinner}.svg`)}
                                        alt="loading"
                                    />
                                    {message && <Span>{message}</Span>}
                                </div>
                            </BlockUI>
                        )}
                    </Transition>

                    {children}
                </>
            </BlockUIContext.Provider>
        </>
    );
};

const initialState = {
    blocking: false,
    spinner: 3,
    message: null,
    setBlocking: () => {},
    setSpinner: () => {},
    setMessage: () => {},
};

export const BlockUIContext = createContext(initialState);
export default BlockUIContextProvider;
