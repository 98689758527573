import React, { useEffect, createContext, useState } from "react";
import firebase from "gatsby-plugin-firebase";

const FirebaseAuthContextProvider = ({ children }) => {    
    const [user, setUser] = useState(undefined);
    const [token, setToken] = useState(undefined);
    const [tokenDate, setTokenDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(undefined);

    const isInRole = (roles) => {
        if (!roles) return false;
        return userData && userData.role && roles.includes(userData.role);
    };

    // onAuthStateChanged
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    // onIdTokenChanged
    useEffect(() => {
        const unsubscribe = firebase.auth().onIdTokenChanged((user) => {
            // console.log("Token refreshed");
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    // onUser changed
    useEffect(() => {        
        if (user === undefined) {
            //console.log("user undefined");
        } else if (user === null) {
            //console.log("user null");
            firebase.auth().signOut();
            setLoading(false);
        } else {
            //console.log("1.) user acquired");
            user.getIdToken().then((token) => {
                setToken(token);
            });
        }
    }, [user]);

    // onToken Changed
    useEffect(() => {
        if (token === undefined) {
            //console.log("token undefined");
        } else if (token === null) {
            //console.log("token null");
            setUser(null);
        } else {
            //console.log("2.) token acquired");
            setTokenDate(new Date());

            fetch(`${process.env.GATSBY_API_URL}/v1/dashboard/user`, {
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw response;
                    }
                    return response.json();
                })
                .then((data) => {
                    // console.log(data);
                    setUserData(data);
                })
                .catch(() => {
                    // En caso de no obtener el usuario desconectamos
                    // setUserObject(null);
                    //console.log("Fallamos al obetener UserObject");
                    setToken(null);
                });
        }
    }, [token]);

    // onUserData Changed
    useEffect(() => {
        if (userData === undefined) {
           // console.log("userData undefined");
        } else if (userData === null) {
            //console.log("userData null");
        } else {
            //console.log("3.) userData acquired");
            setLoading(false);
        }
    }, [userData]);

    const updateIdToken = async () => {
        try {

            // En caso que no tengamos currentUser no hacemos nada
            if (firebase.auth().currentUser == null) { return null; }

            const now = new Date();
            const diff = (now - tokenDate) / 60000;

            const idToken = await firebase.auth().currentUser.getIdToken(diff > 2);
            setToken(idToken);
            return token;
        } catch (err) {            
            return null;
        }
    };

    return (
        <>
            <FirebaseAuthContext.Provider
                value={{
                    user,
                    loading,
                    token,
                    userData,
                    isInRole,
                    setUserData,
                    updateIdToken,
                    isAuthenticated: userData !== null && userData !== undefined,
                }}
            >
                {children}
            </FirebaseAuthContext.Provider>
        </>
    );
};

const initialState = {
    user: null,
    loading: false,
    token: undefined,
    userData: undefined,
    isAuthenticated: false,
    isInRole: () => { },
    setUserData: () => { },
    updateIdToken: async () => { },
};

export const FirebaseAuthContext = createContext(initialState);
export default FirebaseAuthContextProvider;
